import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";

import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

import {
  FlexFormatField,
  FlexSelectField,
  FlexSelectSearchField,
  FlexTextField,
} from "../input/Fields/FlexField/index.js";

import api from "../../services/api.js";
import MainHeaderFX from "../MainHeaderFx/index.js";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError.js";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import "./style.css";

const ClienteModal = ({ open, onClose, clienteId, clientes, onClick=null}) => {
  const initialState = {
    nome: "",
    inscricao: "",
    inscricaoEstadual: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    telefone: "",
    uf: "",
    municipio: {
      id: null,
      nome: null,
      estadoId: null,
      estado: null
    },
    municipioId: "",
    email: "",
  };

  const validationSchemaCNPJ = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"), 
    inscricao: Yup.string().required('CPF/CNPJ é obrigatório'),
    inscricaoEstadual: Yup.string().required('I.E. é obrigatória'),
    cep: Yup.string().required('CEP é obrigatório'),        
    logradouro: Yup.string().required('Endereço é obrigatório'), 
    bairro: Yup.string().required('Bairro é obrigatório'), 
    uf: Yup.string().required('Obrigatório'),
    municipioId: Yup.string().required('O Município é obrigatório'),    
  });

  const validationSchemaCPF = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"), 
    inscricao: Yup.string().required('CPF/CNPJ é obrigatório'),
    cep: Yup.string().required('CEP é obrigatório'),        
    logradouro: Yup.string().required('Endereço é obrigatório'), 
    bairro: Yup.string().required('Bairro é obrigatório'), 
    uf: Yup.string().required('Obrigatório'),
    municipioId: Yup.string().required('O Município é obrigatório'),    
  });

  const isSmallScreen = useMediaQuery("(max-width:825px)");
  const [cliente, setCliente] = useState(initialState);
  const [municipios, setMunicipios] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipio, setMunicipio] = useState({id : 0, name: "", alterou: false});
  const [formatoInscricao, setFormatoInscricao] = useState("##.###.###/####-##");
  const [formatoCNPJ] = useState("##.###.###/####-##");
  const [formatoCPF] = useState("###.###.###-#####");
  const [formatoNulo] = useState("##############");
  const [componenteBotaoCNPJ] = useState("btnBuscarCNPJ");
  const [componenteIconeCNPJNaoCarregando] = useState("iconeCNPJNaoCarregando");
  const [componenteIconeCNPJCarregando] = useState("iconeCNPJCarregando");
  const [validationSchema, setValidationSchema] = useState(validationSchemaCNPJ);
    
  useEffect(() => {
    (async () => {
      try {
        if (clienteId) {
          const apiCalls = [
            api.get(`/v1/clientes/${clienteId}`),
          ];
          const [dataCliente] = await Promise.all(apiCalls);
          
          setCliente((prevState) => {
            return { ...prevState, ...dataCliente.data, uf:dataCliente.data.municipio.estado.id };
          });
        }
      } catch (error) {
        toastError(error);
      }
    })();

    return () => {
      setCliente(initialState);
    };
  }, [clienteId, open]);

  useEffect(() => {
		(async () => {
          setMunicipio({...municipio, alterou: false})
          if (municipio.name !== "" && municipio.name !== undefined && municipio.name !== null) {
            handleFindMunicipioId(municipio.name)
          }
          if (municipio.id !== 0 && municipio.id !== undefined && municipio.id !== null) {
            setCliente(prevState => { return { ...prevState, municipioId: municipio.id} });
            return;
          }  
            
		})();
	}, [municipios]);  

  const fetchMunicipios = (estadoId) => {
    if (estadoId) {
          api.get(`/v1/estados/${estadoId}`).then((response) => {
          setMunicipios(response.data.municipios)
          return response.data.municipios;
        }).catch(() => {
          setMunicipios([])
          return municipios;
        })
      }
    else {
      setMunicipios([])
      return municipios;
    }
  }

  const buscarComponenteId = (value) => {
    return document.getElementById(value);
  }

  const handleIconeCarregandoCnpj = (value) => {
    buscarComponenteId(componenteIconeCNPJCarregando).style.display = value ? "block" : "none";
    buscarComponenteId(componenteIconeCNPJNaoCarregando).style.display =  value ? "none" : "block";
    buscarComponenteId(componenteBotaoCNPJ).disabled = value;
  }
  
  /* Municipios*/  
  useEffect(() => {    
    if (!municipio.alterou) {
      fetchMunicipios(cliente?.uf)
    }

    handleInscricaoChangeMask(cliente.inscricao);
  }, [cliente]); 

  /* Estados*/
  useEffect(() => {    
    const fetchEstados = () => {
      api.get("/v1/estados", {params: {size:28}}).then((value) => {setEstados(value.data.estados)}).catch((error) => { toastError(error); });
    };

    fetchEstados(); 
  }, []); 

  const handleFindUfId = (value) => {  
    let id = "";

    estados.map((u) => {
      if (u.uf === value)
        id = u.id;
      fetchMunicipios(id) 
      return id;    
    });
    fetchMunicipios(id)
    return id;  
  }

  const handleFindMunicipioId = (value) => {  
    let id = "";

    municipios.map((u) => {
      if (u.nome === value) {
        id = u.id;
        setMunicipio({...municipio, id, alterou: true})
        return id;
      }  
    });

    return id;
  }

  const handleClose = () => {
    onClose();
    setCliente(initialState);
  };

  const handleSaveCliente = async (values) => {
    try {
      const clienteData = { ...values, inscricaoEstadual: values.inscricaoEstadual === "" && formatoInscricao === formatoCPF ? "ISENTO" : values.inscricaoEstadual };

			if (clienteId) { 
        await api.put(`/v1/clientes/${clienteId}`, clienteData);
        let clienteIndex = clientes.findIndex((cli) => cli.id === clienteId);
			  clientes[clienteIndex] = clienteData;
			} 
      else {     
				await api.post("/v1/clientes", clienteData).then((response) => {
          clienteData.id = response.data.id;
        });

        if (!onClick) {
          clientes.push(clienteData);
        }
			}

      if (onClick) {
        onClick(true);
      }

			handleClose(true);

      onClose();
    } catch (error) {
      toastError(error);
    }
  };

  const handleInscricaoChangeMask = (value) => {
      setFormatoInscricao(value.length > 11 ? formatoCNPJ : value.length === 11 ? formatoCPF : formatoNulo);
      setValidationSchema(formatoCNPJ === formatoInscricao ? validationSchemaCNPJ : validationSchemaCPF);
  }

  const handleSearchCNPJ = (values) => {    
    try {     
      const clienteData = { ...values };

      const cnpj = clienteData.inscricao;    
      setCliente(values);
      
      if (cnpj.length === 14) {
        handleIconeCarregandoCnpj(true);
        fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
          .then(result => result.json())
          .then(data => {
            const { status } = data;
            
            if (status !== 429 && status !== 400)
            {
              const { razao_social } = data;
              const { numero, complemento, cep, bairro, logradouro, email } = data?.estabelecimento;
              const cidade = data?.estabelecimento?.cidade?.nome;
              const ufData = data?.estabelecimento?.estado?.sigla;
              const telefone = data?.estabelecimento?.ddd1 + data?.estabelecimento?.telefone1; 
              const inscricaoEstadual = data?.estabelecimento?.inscricoes_estaduais.length > 0 ? data?.estabelecimento?.inscricoes_estaduais[0].inscricao_estadual : "";
              
              const uf = handleFindUfId(ufData);

              setMunicipio({...municipio, name: cidade, alterou: true})

              const municipioId = handleFindMunicipioId(cidade)

              setCliente(prevState => ({...prevState, nome: razao_social, inscricaoEstadual,
                                           cep, 
                                           numero,
                                           complemento: complemento ? complemento : "",
                                           bairro,
                                           logradouro,
                                           email,
                                           telefone,
                                           uf, 
                                           municipioId} ));
            }
            handleIconeCarregandoCnpj(false);            
          })
          .catch(error => {
            toastError(error); 
          });
      } else {
        alert("A busca funciona apenas para CNPJ.")
      }
      
    } catch (error) {
      toastError(error) 
    }
  }  

  return (
    <div>
      <Dialog
        open={open}
        scroll="paper"
        fullWidth={true}
        fullScreen={isSmallScreen}
        maxWidth={'true'}
        maxHeight={true}
        PaperProps={
          !isSmallScreen
            ? { style: { maxHeight: "80vh", maxWidth: "1000px", }, }
            : undefined
        }
      >
        <Formik
          initialValues={cliente}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveCliente(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form
            style={{display: "flex", 
              flexDirection: "column", 
              height: "100%", 
              gap:"1em"}}  >
              {isSmallScreen
                ? (
                  <div style={{height: "10%"}}>
                    <MainHeaderFX
                      title={i18n.t("cliente.title")}
                      leftContainerType={"modal"}
                      rightContainerType={"modal"}
                      handleCloseModal={handleClose}
                      handleSaveModal={() => handleSaveCliente(values)}
                    />
                  </div>
                )
                : (
                  <DialogTitle>{i18n.t("cliente.title")}</DialogTitle>
                )
              }
              <DialogContent className="dialogContent" dividers>
                <Container className="cliente-dataCompany">
                  <Container className= {isSmallScreen ? "cliente-dadosSmall" : "cliente-dados"}>

                    <div className="cliente-info">
                      <div className="cliente-inscricao">
                        <FlexFormatField
                          style={{ paddingRight: "20px"}}                        
                          autoFocus={true}
                          label={i18n.t("cliente.fields.inscricao")}
                          inputMode="numeric"
                          format={formatoInscricao}                       
                          allowEmptyFormatting={false}
                          name="inscricao"
                          width="25%"
                          mask=" "
                          error={touched.inscricao && Boolean(errors.inscricao)}
                          helperText={touched.inscricao && errors.inscricao}
                          onChangeEvent={handleInscricaoChangeMask}
                          onChange
                        /> 
                        <Button 
                            style={{ height: "50px", marginTop: "11px"}}                        
                            onClick={() => handleSearchCNPJ(values)}                                     
                            color="primary"
                            disabled={isSubmitting}
                            variant="contained"  
                            id={componenteBotaoCNPJ}
                          >
                          <SyncIcon id={componenteIconeCNPJCarregando} color="primary" style={{animation: "spin 1s ease infinite", display:"none"}}/>
                          <SearchOutlinedIcon id={componenteIconeCNPJNaoCarregando} />
                          
                        </Button>
                      </div>
                      <div className="cliente-linha">
                        <FlexTextField
                          label={i18n.t("cliente.fields.nome")}
                          name="nome"
                          width={isSmallScreen ? "100%" : "70%"}
                          error={touched.nome && Boolean(errors.nome)}
                          helperText={touched.nome && errors.nome}
                        />
                              
                        <FlexTextField
                          label={i18n.t("cliente.fields.inscricaoEstadual")}
                          name="inscricaoEstadual"
                          width={isSmallScreen ? "100%" : "30%"}
                          error={formatoInscricao !== formatoCPF && touched.inscricaoEstadual && Boolean(errors.inscricaoEstadual)}
                          helperText={formatoInscricao !== formatoCPF && touched.inscricaoEstadual && errors.inscricaoEstadual}
                        />
                      </div>
                      <div className="cliente-linha">
                        <FlexTextField
                          label={i18n.t("cliente.fields.logradouro")}
                          name="logradouro"
                          width="80%"
                          error={touched.logradouro && Boolean(errors.logradouro)}
                          helperText={touched.logradouro && errors.logradouro}
                        />
                        <FlexTextField
                          label={i18n.t("cliente.fields.numero")}
                          name="numero"
                          width="20%"
                        />
                      </div>
                      <div className="cliente-linha">
                        <FlexTextField
                          label={i18n.t("cliente.fields.complemento")}
                          name="complemento"
                          width="50%"
                        />
                        <FlexTextField
                          label={i18n.t("cliente.fields.bairro")}
                          name="bairro"
                          width="50%"
                          error={touched.bairro && Boolean(errors.bairro)}
                          helperText={touched.bairro && errors.bairro}
                        />
                      </div>
                      <div className="cliente-linha">
                        <FlexFormatField
                          label={i18n.t("cliente.fields.cep")}
                          name="cep"
                          width="25%"
                          inputMode="numeric"
                          format="#####-###"
                          error={touched.cep && Boolean(errors.cep)}
                          helperText={touched.cep && errors.cep}
                        />   

                        <FlexSelectField                         
                            label={i18n.t("UF")}
                            name="uf"
                            arrayList={estados}
                            propArray={"uf"}
                            propArrayId={"id"}                      
                            error={touched.uf && Boolean(errors.uf)}
                            helperText={touched.uf && errors.uf}
                            width={"10%"}
                            style={{backgroundColor: "red"}} 
                            formControlMarginTop="8px"
                            customOnChange={(event) => {setMunicipio({id: null, municipioId: null, alterou: false}); fetchMunicipios(event.target.value)}}
                          /> 

                          <FlexSelectSearchField
                            width={"calc(65% - 1em)"}
                            label={i18n.t("cliente.fields.municipio")}
                            name="municipioId"
                            arrayList={municipios}
                            propArray={"nome"}
                            propArrayId={"id"}
                            formControlMarginTop="8px"
                            
                            error={touched.municipioId && Boolean(errors.municipioId)}
                            helperText={touched.municipioId && errors.municipioId}
                          />
                      </div>
                      <div className="cliente-linha">
                        <FlexTextField
                          label={i18n.t("cliente.fields.telefone")}
                          name="telefone"
                          width="50%"
                        />
                        <FlexTextField
                          label={i18n.t("cliente.fields.email")}
                          name="email"
                          width="50%"
                        />
                      </div>
                      
                      
                    </div>
                  </Container>

                  <br />

          
                </Container>
              </DialogContent>

              {!isSmallScreen && (
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("button.cancel")}
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                  >
                    {clienteId
                      ? `${i18n.t("button.save")}`
                      : `${i18n.t("button.add")}`}
                    {isSubmitting && <CircularProgress size={24} />}
                  </Button>
                </DialogActions>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ClienteModal;
