const messages = {
  pt: {
    translations: {
      titleSystem: {
        title: "Nota Certa",
        subTitle: "NFCe",
      },
      languages: {
        language1: "Português",
        language2: "English",
        language3: "Español",
      },
      page404: {
        message:
          "Parece que você se perdeu, clique em uma das opções do lado esquerdo para voltar à página!",
      },
      drawerMenu: {
        title: "Nota Certa",
        items: {
          mainItems: {
            emission: "Emitir NFC-e",
            registrations: "Cadastros",
            items: "Notas Emitidas",
            settings: "Configurações",
            logout: "Sair",
          },
          subItems: {
            companies: "Empresa",
            users: "Usuários",
            products: "Produtos",
            customers: "Clientes",
          },
        },
      },
      ncmSearchModal: {
        title: "Pesquisa NCM",
        ncm: "NCM",
      },
      search: {
        search: "Pesquisar",
      },
      genericPage: {
        headerTitle: "Cabeçalho do Título",
        titleButton: "Botão do Título",
      },
      login: {
        message: "Vamos começar!",
        login: "Login",
        form: {
          email: "E-mail",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
        },
      },
      button: {
        add: "Adicionar",
        exit: "Sair",
        edit: "Editar",
        delete: "Excluir",
        save: "Salvar",
        cancel: "Cancelar",
        enter: "Entrar",
        close: "Fechar",
        replace: "Substituir",
      },
      toasts: {
        deleted: "Excluído com sucesso!",
        saved: "Gravado com sucesso!",
        embalagemError: "Preencher todos os campos!",

        exceptions: {
          unauthorizedUserCreationAndUpdate: "Apenas administradores podem criar e alterar outros usuários!",
        },
      },
      validation: {
        error: {},
        warning: {
          tooLong: "Muito longo!",
          tooShort: "Muito curto!",
          required: "Obrigatório",
          emailInvalid: "E-mail inválido",
        },
      },
      empresa: {
        title: "Empresa",
        pluralTitle: "Empresa",

        fields: {
          razaoSocial: "Razão Social",
          nomeFantasia: "Nome fantasia",
          cnpj: "CNPJ",
          inscricaoEstadual: "Inscrição Estadual",

          endereco: {
            title: "Endereço",
            cep: "CEP",
            logradouro: "Endereço",
            numero: "Número",
            complemento: "Complemento",
            bairro: "Bairro",
            municipio: "Município",
            uf: "UF",
            pais: "País",
          },
          tributacao: {
            title: "Regime tributário",
            apuracaoImposto: "Tipo apuração", 
            cnaePrincipal: "CNAE",
          },
        },
      },
      produto: {
        title: "Produto",
        pluralTitle: "Produtos",
        fields: {
          nome: "Nome",
          statusAtivo: "Ativo",
          statusInativo: "Inativo",
          embalagem: "Embalagem",
          preco: "Preço",
        },
      },

      cliente: {
        title: "Cliente",
        pluralTitle: "Clientes",
        fields: {
          nome: "Nome",
          inscricao: "CPF/CNPJ",
          inscricaoEstadual: "I.E.",
          inscricaoEstadualIndicador: "Indicador I.E.",
          logradouro: "Endereço",
          numero: "Número",
          complemento: "Complemento",
          bairro: "Bairro",
          cep: "CEP",
          telefone: "Telefone",
          municipio: "Município",
          uf: "UF",
          email: "Email",
        },
      },

      vendas: {
        title: "Vendas",
        table: {
          status: "Status",
          emissao: "Emissão",
          numero: "Número",
          numeroSmartphone: "N.",
          valor: "Valor",
          chave: "Chave",
          acoesLista: {
            visualizar: "Visualizar",
            visualizarErro: "Visualizar Erro",
            download: "Download",
            imprimir: "Imprimir",
            copiarChave: "Copiar Chave",
            compartilhar: "Compartilhar",
            cancelarNota: "Cancelar Nota",
            corrigirReenviar: "Corrigir / Reenviar",
            inutilizar: "Inutilizar",
          }
        }
      },

      empresaConfig: {
        geral: {
          title: "Geral",
          ambiente: "Ambiente",
          emitirNfce: "Emitir NFC-e",
          emitirNfe: "Emitir NF-e",
        },
        ultimoNumero: "Número NFC-e",
        serie: "Série",
        nfce:{
          title: "NFC-e", 
          informarCpf: "Informar CPF",
          csc: "ID Código",
          codigoCsc: "Cód Segurança",
        },
        nfe:{
          title: "NF-e", 
        },
        certificado: {
          title: "Certificado",
          hashSenha: "Senha",
          certificado: "Certificado",
          dataValidade: "Validade",
        },
        tributacaoPadrao: {
          title: "Tributação Padrão",
          operacao: "Operação",
          cstCsosnIcms: " CSOSN",
          cstPisCofins: "Pis Cofins",
          ncm: "NCM",
          cest: "CEST",
          padrao: "Usa tributação padrão",
        },
        validations: {
          csosnInvalid: "CSOSN Inválido!",
          nenhumTipo: "É necessário marcar pelo menos 1 tipo de emissão!",
          nfce: {
            invalid: "Desmarque a emissão de NFC-e ou preencha os campos corretamente!",
            numero: "NFC-e: O último número deve ser pelo menos zero!",
          },
          nfe: {
            invalid: "Desmarque a emissão de NF-e ou preencha os campos corretamente!",
            numero: "NF-e: O último número deve ser pelo menos zero!",
          },
        }
      },

      emissao: {
        title: "NFC-e",
      },

      user: {
        title: "Usuário", 
        pluralTitle: "Usuários",
        field: {
          name: "Nome",
          password: "Senha",
          email: "E-mail",
          profile: "Perfil",
          admin: "Administrador",
          user: "Usuário",
        },
      },
    },
  },
};

export { messages };
