import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  CategoryOutlined,
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  LogoutOutlined,
  NoteAddOutlined,
  NoteAltOutlined,
  PersonOutlineOutlined,
  SettingsOutlined,
  StoreOutlined,
} from "@mui/icons-material";

import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';


import { AuthContext } from "../context/Auth/AuthContext";
import { i18n } from "../translate/i18n";
import isUserAdmin from "../utils/funcoes/validacoes/isUserAdmin";
import UserModal from "../components/UserModal";

const MainListItems = ({ closeDrawer, isSmallScreen }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { handleLogout, user } = useContext(AuthContext);
  const navigate = useNavigate(false);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [openRegistrationsMenu, setOpenRegistrationsMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClickRegistrationsMenu = () => { setOpenRegistrationsMenu((previousValue) => !previousValue); };

  const handleClickLogout = () => { handleLogout(); };

  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    navigate(route);
    isSmallScreen && closeDrawer();
  };

  const handleOpenUserModal = () => { setUserModalOpen(true); };

  const handleCloseUserModal = () => { setUserModalOpen(false); };

  const handleUsersMenuOptionClick = () => {
    if (isUserAdmin(user)) handleListItemClick(3, "users");
    else handleOpenUserModal();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        userId={user && user.id}
        users={[user]}
      />

      <div>
        {/*
        ***---- Registrations ----***
        */}
        <div onClick={handleClickRegistrationsMenu}>
          <ListItemButton button="true" selected={[0, 1, 2, 3].includes(selectedIndex)}>
            <ListItemIcon>
              <NoteAltOutlined />
            </ListItemIcon>

            <ListItemText primary={i18n.t("drawerMenu.items.mainItems.registrations")} />

            <IconButton>{openRegistrationsMenu ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemButton>
        </div>

        {/*
        ***---- Registrations Collapse ----***
        */}
        <Collapse in={openRegistrationsMenu} component="li" timeout="auto" unmounonexit="true">
          <List disablePadding>
            <ListItemButton
              button="true"
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0, "empresas")}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <StoreOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.companies")} />
            </ListItemButton> 

            <ListItemButton
              button="true"
              selected={selectedIndex === 1}
              onClick={() => handleListItemClick(1, "produtos")}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <CategoryOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.products")} />
            </ListItemButton>

            {false /* to-do:CAMPOS_NFE */ && <ListItemButton
              button="true"
              selected={selectedIndex === 2}
              onClick={() => handleListItemClick(2, "clientes")}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <ContactsOutlinedIcon />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.customers")} />
            </ListItemButton>}

            <ListItemButton
              button="true"
              selected={selectedIndex === 3}
              onClick={handleUsersMenuOptionClick}
              style={{ paddingLeft: "25px" }}
            >
              <ListItemIcon>
                <PersonOutlineOutlined />
              </ListItemIcon>

              <ListItemText primary={i18n.t("drawerMenu.items.subItems.users")} />
            </ListItemButton>
          </List>
        </Collapse>

        {/*
        ***---- Notas ----***
        */}
        <ListItemButton
          button="true"
          onClick={() => {
            if (window.location.href.includes("/")) window.location.href = "/?emitirNfce=1"   
          }}
        >
          <ListItemIcon>
            <NoteAddOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.emission")} />
        </ListItemButton>

        <ListItemButton
          button="true"
          selected={[4].includes(selectedIndex)}
          onClick={() => handleListItemClick(4, "Notas")}
        >
          <ListItemIcon>
            <ListAltOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.items")} />
        </ListItemButton>

        {/*
        ***---- Settings ----***
        */}
        <ListItemButton
          button="true"
          selected={[5].includes(selectedIndex)}
          onClick={() => handleListItemClick(5, "empresaConfig")}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>

          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.settings")} />
        </ListItemButton>

        {/*
        ***---- Logout ----***
        */}
        <ListItemButton button="true" onClick={handleClickLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary={i18n.t("drawerMenu.items.mainItems.logout")} />
        </ListItemButton>
      </div>
    </>
  );
};

export default MainListItems;
